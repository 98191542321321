import React, { useContext } from 'react';

import './About.css';
import AboutImg from '../../assets/img/profile/aboutMe.svg';
// import { aboutData } from '../../data/aboutData'



function About() {

    return (
        <div className="about" id="about" style={{ backgroundColor: "rgb(33, 33, 33)" }} >
            <div className="line-styling" >
                <div className="style-circle" style={{ background: "rgb(245, 101, 57)" }}></div>
                <div className="style-circle" style={{ background: "rgb(245, 101, 57)" }}></div>
                <div className="style-line" style={{ background: "rgb(245, 101, 57)" }}></div>
            </div>
            <div className="about-body">
                <div className="about-description">
                    <h2>Who I am</h2>
                    <p >My name's Sougata Das. I'm a web developer, open source contributor and Competetive programmer <br /><br /> I am interested in developing new component , contributing in projects and solving real life problems.  I love to learn and explore new arenas.
                        <br />
                        <br />
                        Some Fact about me: <br />
                        <br />
                        Fullstack developer | Competitive Programmer | Open Source contributor<br />
                        📝 Final year undergrad pursuing Computer Engineering.<br />
                        🤞Contributed to open source, GSoC'21 under @ScoreLab organization<br />
                        🥳 SDE Intern at @Upstox<br />
                        🎢 Ex- HackerRank, EPNS <br/>
                        🏆 ICPC regionalist, SIH'22 Finalist<br />

                    </p>
                </div>
                <div className="about-img">
                    <img
                        src={AboutImg}
                        alt=""
                    />
                </div>
            </div>
        </div >

    )
}

export default About
