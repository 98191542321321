import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { makeStyles } from '@material-ui/core/styles';
import profileImg from '../../assets/img/profile/profileNew.jpeg';
import './Landing.css'

import { FaTwitter, FaLinkedin, FaGithub, FaYoutube, FaBlogger, FaBlog } from "react-icons/fa";


function Landing() {

    const useStyles = makeStyles((t) => ({
        resumeBtn: {
            color: "orange",
            borderRadius: '30px',
            textTransform: 'inherit',
            textDecoration: 'none',
            width: '150px',
            fontSize: '1rem',
            fontWeight: '500',
            height: '50px',
            fontFamily: 'var(--primaryFont)',
            border: `3px solid `,
            transition: '100ms ease-out',
            "&:hover": {

                color: "pink",
                border: `3px solid :"red`,

            },
            [t.breakpoints.down('sm')]: {
                width: '180px',
            },
        },
        contactBtn: {

            borderRadius: '30px',
            textTransform: 'inherit',
            textDecoration: 'none',
            width: '150px',
            height: '50px',
            fontSize: '1rem',
            fontWeight: '500',
            fontFamily: 'var(--primaryFont)',

            transition: '100ms ease-out',
            "&:hover": {
                color: "pink",
                border: `3px solid :"red`,
            },
            [t.breakpoints.down('sm')]: {
                display: 'none',
            },
        }
    }));

    const classes = useStyles();

    return (
        <div className="landing"   >
            <div className="landing--container">
                <div className="landing--container-left" style={{ background: "rgb(245, 101, 57)" }}>
                    <div className="lcl--content">

                        <a target="_blank" rel="noreferrer">
                            <FaLinkedin onClick={e => window.open("https://www.linkedin.com/in/rijusougata13/")} className={classes.resumeBtn} className="landing--social" style={{ color: "white" }} />
                        </a>

                        <a target="_blank" rel="noreferrer">
                            <FaGithub onClick={e => window.open("https://github.com/rijusougata13")} className="landing--social" style={{ color: "white" }} />
                        </a>


                        <a target="_blank" rel="noreferrer">
                            <FaTwitter className="landing--social" onClick={e => window.open("https://twitter.com/rijusougata13")} style={{ color: "white" }} />
                        </a>


                        <a target="_blank" rel="noreferrer">
                            <FaBlogger className="landing--social" onClick={e => window.open("https://blog.rijusougata13.com/")} style={{ color: "white" }} />
                        </a>



                    </div>
                </div>
                <img src={profileImg} alt="" className="landing--img" />
                <div className="landing--container-right" style={{ background: "rgb(33, 33, 33)" }}>
                    <div className="lcr--content" style={{ color: "red" }}>
                        <div style={{ color: "white", width: "100%", display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "space-between" }}>
                            <div>
                                <h2>Hey</h2>
                                <br />
                                <h1>I am Sougata Das</h1>
                                <h3>Web Developer, Competetive Programmer, Open Source Contributor</h3>
                            </div>
                            <br />
                            <a download="resume" target="_blank">
                                <Button onClick={e => window.open("https://drive.google.com/file/d/1xWxpVAwdEX2uTrJNaNhNQIiWlp8yk1jd/view")} className={classes.resumeBtn}> Resume</Button>
                            </a>
                        </div>

                        {/* <div className="lcr-buttonContainer">
                             
                               
                            
                            <NavLink to="/#contacts" smooth={true} spy="true" duration={2000}>
                                <Button className={classes.contactBtn}>Contact</Button>
                            </NavLink>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landing
