import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./contact-form.styles.css";

const Achievement = () => {
  return (
    <div id="achievement" style={{ backgroundColor: "rgb(33, 33, 33)" }}>
      <h1 className="pt-3 text-center font-details-b pb-3">Achievement</h1>
      <div className="list">
        <ul>
          <li> Finalist in Smart India Hackthond,2022</li>
          <li>Google Summer of Code'21 under Scorelab</li>
          <li>Google Kickstart Ranked Under 1500 (Global)</li>
          <li>TCS Codevita S09 Ranked under 500 (AIR)</li>
          <li>Master the mainframe level3 finisher</li>
          <li>Winner at collge level hackathon</li>
        </ul>
      </div>
    </div>
  );
};

export default Achievement;
