import React from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Card from "react-bootstrap/Card";
import VideotorriumImg from "../../assets/img/experience/videtorrium.jpg";
import GSOCIMG from "../../assets/img/experience/summer-of-code-logo.svg";
import HACKERRANK from "../../assets/img/experience/hackerrank.jpeg";
import EPNS from "../../assets/img/experience/epns.jpeg";
import Upstox from '../../assets/img/experience/upstox.png';
import Fincent from '../../assets/img/experience/fincent.png';
import Tilt from "react-tilt";
import "./experience.styles.css";

const Experience = () => {
  return (
    <div id="experience" style={{ backgroundColor: "rgb(33, 33, 33)" }}>
      <h1 className="pt-3 text-center font-details-b pb-3">EXPERIENCE</h1>
      {/* Deloitte */}
      {/* <Jumbotron className="jumbo-style"> */}
      <Container>
        <Tilt options={{ max: 25 }}>
          <Card
            className="experienceCard"
            // onClick={(e) =>
            //   // window.open(
            //   //   "https://drive.google.com/file/d/10kaocVgfXcQ0qqCschmtwv-NY7Qblwht/view?usp=sharing"
            //   // )
            // }
          >
            <Card.Header
              as="h5"
              className="d-flex justify-content-center flex-wrap"
            >
              <Card.Img
                variant="top"
                className="img-resize"
                src={Fincent}
                alt="Fincent logo"
              />
            </Card.Header>
            <Card.Body className="d-flex justify-content-center flex-column">
              <div>
                <Card.Title className="text-center">
                  Software Developer 1
                </Card.Title>
              </div>
              <div>
                <div className="text-center style">
                  <strong className="body-title-style ">Fincent</strong>
                  {/* <p
                    onClick={(e) =>
                      window.open(
                        "https://drive.google.com/file/d/10kaocVgfXcQ0qqCschmtwv-NY7Qblwht/view?usp=sharing"
                      )
                    }
                  >
                    {" "}
                    <strong>
                      {" "}
                      <a> View Certificate </a>{" "}
                    </strong>{" "}
                  </p> */}
                  <br />
                  <strong>Technology:</strong> Node.js, Fastify, Express, React, PostgreSQL, Elasticsearch, AWS
              
                  <br />
                  <strong>Duration:</strong> July 2023 - Current
                  <br />
                  <strong> Description </strong>
                  <ul className="text-left">
                    <li>
                    Collaborated with the core team to develop a robust platform for finance and payroll management from the ground up
                    </li>
                    <li>
                    Enhanced backend infrastructure by integrating advanced error-handling mechanisms and optimizing database queries;
decreased response time by 40% and boosted overall application performance by 35%
                      </li>                   
                  </ul>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Tilt>
      </Container>
      <Container>
        <Tilt options={{ max: 25 }}>
          <Card
            className="experienceCard"
            // onClick={(e) =>
            //   // window.open(
            //   //   "https://drive.google.com/file/d/10kaocVgfXcQ0qqCschmtwv-NY7Qblwht/view?usp=sharing"
            //   // )
            // }
          >
            <Card.Header
              as="h5"
              className="d-flex justify-content-center flex-wrap"
            >
              <Card.Img
                variant="top"
                className="img-resize"
                src={Upstox}
                alt="Upstox logo"
              />
            </Card.Header>
            <Card.Body className="d-flex justify-content-center flex-column">
              <div>
                <Card.Title className="text-center">
                  Software Developer Intern
                </Card.Title>
              </div>
              <div>
                <div className="text-center style">
                  <strong className="body-title-style ">Upstox</strong>
                  {/* <p
                    onClick={(e) =>
                      window.open(
                        "https://drive.google.com/file/d/10kaocVgfXcQ0qqCschmtwv-NY7Qblwht/view?usp=sharing"
                      )
                    }
                  >
                    {" "}
                    <strong>
                      {" "}
                      <a> View Certificate </a>{" "}
                    </strong>{" "}
                  </p> */}
                  <br />
                  <strong>Technology:</strong> Java Spring Boot, AWS, Docker, MySQL, MongoDB
              
                  <br />
                  <strong>Duration:</strong> Jan 2023 - July 2023
                  <br />
                  <strong> Description </strong>
                  <ul className="text-left">
                    <li>
                     Working on t-growth upstox team, where handling users onboarding and converting them to customer. Creating API, handling database, managing authentications and overall.
                    </li>
                   
                  </ul>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Tilt>
      </Container>
      <Container>
        <Tilt options={{ max: 25 }}>
          <Card
            className="experienceCard"
            // onClick={(e) =>
            //   // window.open(
            //   //   "https://drive.google.com/file/d/10kaocVgfXcQ0qqCschmtwv-NY7Qblwht/view?usp=sharing"
            //   // )
            // }
          >
            <Card.Header
              as="h5"
              className="d-flex justify-content-center flex-wrap"
            >
              <Card.Img
                variant="top"
                className="img-resize"
                src={HACKERRANK}
                alt="HackerRank logo"
              />
            </Card.Header>
            <Card.Body className="d-flex justify-content-center flex-column">
              <div>
                <Card.Title className="text-center">
                  Software Developer Intern
                </Card.Title>
              </div>
              <div>
                <div className="text-center style">
                  <strong className="body-title-style ">HackerRank</strong>
                  {/* <p
                    onClick={(e) =>
                      window.open(
                        "https://drive.google.com/file/d/10kaocVgfXcQ0qqCschmtwv-NY7Qblwht/view?usp=sharing"
                      )
                    }
                  >
                    {" "}
                    <strong>
                      {" "}
                      <a> View Certificate </a>{" "}
                    </strong>{" "}
                  </p> */}
                  <br />
                  <strong>Technology:</strong> React JS, Ruby , Rails, Node JS,
                  AWS , Docker, Kubernetes,
                  <br />
                  <strong>Duration:</strong> APRIL 2022 - Current
                  <br />
                  <strong> Description </strong>
                  <ul className="text-left">
                    <li>
                      HackerRank Platform: Worked on improving user experience,
                      developed apis for users to connect learning platform and
                      integrate them in hackerrank work which gives us 30% more
                      traffic
                    </li>
                    <li>
                      HackerResume: Implemented new features, improved response
                      speed in apis, added new pages for better user experience.
                      Overall increased 45 % traffic in hackerresume website
                      also Working on integrating application tracker with
                      hackerresume. Created APIs in rails and used postgres to
                      store data
                    </li>
                  </ul>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Tilt>
      </Container>
      <Container>
        <Tilt options={{ max: 25 }}>
          <Card
            className="experienceCard"
            // onClick={(e) =>
            //   window.open(
            //     "https://drive.google.com/file/d/10kaocVgfXcQ0qqCschmtwv-NY7Qblwht/view?usp=sharing"
            //   )
            // }
          >
            <Card.Header
              as="h5"
              className="d-flex justify-content-center flex-wrap"
            >
              <Card.Img
                variant="top"
                className="img-resize"
                src={EPNS}
                alt="EPNS logo"
              />
            </Card.Header>
            <Card.Body className="d-flex justify-content-center flex-column">
              <div>
                <Card.Title className="text-center">
                  Fullstack Developer Intern
                </Card.Title>
              </div>
              <div>
                <div className="text-center style">
                  <strong className="body-title-style ">EPNS</strong>
                  {/* <p
                    onClick={(e) =>
                      window.open(
                        "https://drive.google.com/file/d/10kaocVgfXcQ0qqCschmtwv-NY7Qblwht/view?usp=sharing"
                      )
                    }
                  >
                    {" "}
                    <strong>
                      {" "}
                      <a> View Certificate </a>{" "}
                    </strong>{" "}
                  </p> */}
                  <br />
                  <strong>Technology:</strong> React JS, Material Ui, Firebase,
                  Node JS, Solidity, Web3, Truffle, Ganache, Metamask, IPFS
                  Redux
                  <br />
                  <strong>Duration:</strong> Oct 2021 - March 2022
                  <br />
                  <strong> Description </strong>
                  <ul className="text-left">
                    <li>
                      ◦ Web3 Decentralized App: Worked on web3 notification
                      system to connect users over polygon network. Conducted
                      workload assessments and devised new operational processes
                      that led to a 40% increase in productivity.
                    </li>
                    <li>
                      {" "}
                      ◦ Frontend Improvement: Contributed on improving their
                      website design and connect frontend to web3 network. Also
                      worked on epns browser extension and epns mobile app
                    </li>
                  </ul>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Tilt>
      </Container>
      <Container>
        <Tilt options={{ max: 25 }}>
          <Card
            className="experienceCard"
            // onClick={(e) =>
            //   window.open(
            //     "https://drive.google.com/file/d/10kaocVgfXcQ0qqCschmtwv-NY7Qblwht/view?usp=sharing"
            //   )
            // }
          >
            <Card.Header
              as="h5"
              className="d-flex justify-content-center flex-wrap"
            >
              <Card.Img
                variant="top"
                className="img-resize"
                src={GSOCIMG}
                alt="GSoC logo"
              />
            </Card.Header>
            <Card.Body className="d-flex justify-content-center flex-column">
              <div>
                <Card.Title className="text-center">
                  Student Developer
                </Card.Title>
              </div>
              <div>
                <div className="text-center style">
                  <strong className="body-title-style ">
                    Frontend Developer
                  </strong>
                  <p
                    // onClick={(e) =>
                    //   window.open(
                    //     "https://drive.google.com/file/d/10kaocVgfXcQ0qqCschmtwv-NY7Qblwht/view?usp=sharing"
                    //   )
                    // }
                  >
                    {" "}
                    <strong>
                      {" "}
                      <a> View Certificate </a>{" "}
                    </strong>{" "}
                  </p>
                  <br />
                  <strong>Technology:</strong> React JS, Material Ui, Firebase,
                  Redux, Storybook, Cypress
                  <br />
                  <strong>Duration:</strong> May 2021 - September 2021
                  <br />
                  <strong> Description </strong>
                  <ul className="text-left">
                    <li>
                      1. Worked on an online tutorial platform React Js as
                      frontend and firebase as the backend. 2. Designed view in
                      Figma and implemented those in the project, used material
                      UI library for styling and components.
                    </li>
                    <li>
                      3. Implemented some features such as follow, following the
                      system between organization members and regular members,
                      Autocomplete search.
                    </li>
                    <li>
                      4. Worked on a storybook for all the components and hosted
                      it on Github pages. 5. Worked on e2e tests for component
                    </li>
                  </ul>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Tilt>
      </Container>
      {/* </Jumbotron> */}

      {/* Accenture */}
      {/* <Jumbotron className="jumbo-style"> */}
      <Container>
        <Tilt options={{ max: 25 }}>
          <Card
            className="experienceCard"
            // onClick={(e) =>
            //   window.open(
            //     "https://drive.google.com/file/d/1WO4kU--TW7Xn_1NxatpbKzwg2NF8exO-/view?usp=sharing"
            //   )
            // }
          >
            <Card.Header
              as="h5"
              className="d-flex justify-content-center flex-wrap"
            >
              <Card.Img
                variant="top"
                className="img-resize"
                src={VideotorriumImg}
                alt="GSoC logo"
              />
            </Card.Header>
            <Card.Body className="d-flex justify-content-center flex-column">
              <div>
                <Card.Title className="text-center">
                  Software Developer
                </Card.Title>
              </div>
              <div>
                <div className="text-center style">
                  <strong className="body-title-style ">
                    Fullstack Developer
                  </strong>
                  <p>
                    {" "}
                    <strong>
                      {" "}
                      <a> View Experience Letter </a>{" "}
                    </strong>{" "}
                  </p>
                  <br />
                  <strong>Technology:</strong> React JS, Material Ui, Firebase,
                  Redux, Node, Express, AWS, Mongo , Nextjs
                  <br />
                  <strong>Duration:</strong> Jan 2021 - Current
                  <br />
                  <strong> Description </strong>
                  <ul className="text-left">
                    <li>
                      Worked as a frontend developer and built client sites.
                      Increased traffic by more than 30% for the attractive and
                      unique looks
                    </li>
                    <li>
                      Worked on npm modules, e2e tests, API integration,
                      implemented complex and cool features also worked with
                      material UI, ant design, styled-components library. Worked
                      as a backend developer and wrote APIs for new features.
                      Implemented razor pay the payment for client sites.
                    </li>
                  </ul>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Tilt>
      </Container>
      {/* </Jumbotron> */}
    </div>
  );
};

export default Experience;
